import * as styles from ".././authentication.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { I18n } from "react-redux-i18n";
import { getResetPasswordSchema } from "../schema";
import { muiStylesAuthentication } from "../muiStylesAuthentication";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { getResetPasswordEmail } from "../../../redux/actions/authActions";
import {
  selectResetPasswordEmailLoading,
  selectResetPasswordEmailSuccess,
} from "../../../redux/selectors/authSelectors";
import clsx from "clsx";
import { handleNavigateToLogin } from "../../../utils/paths";

export type GetResetPasswordData = {
  email: string;
};

export const GetResetPasswordForm: FC = () => {
  const dispatch = useDispatch();

  const getEmailSuccedeed = useSelector(selectResetPasswordEmailSuccess);
  const loading = useSelector(selectResetPasswordEmailLoading);

  const [requestSucceeded, setRequestSucceeded] = useState(false);
  const [providedEmail, setProvidedEmail] = useState("");

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current && getEmailSuccedeed) {
      setRequestSucceeded(true);
    }
  }, [getEmailSuccedeed]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<GetResetPasswordData>({
    defaultValues: {
      email: "",
    },
    mode: "all",
    resolver: yupResolver(getResetPasswordSchema),
  });

  const onSubmit = handleSubmit((data: GetResetPasswordData): void => {
    data.email = data.email.toLowerCase();
    setProvidedEmail(data.email);
    dispatch(getResetPasswordEmail(data));
  });

  return (
    <div>
      {requestSucceeded ? (
        <div className={clsx(styles.loginForm, styles.loginFormSuccess)}>
          <h1 className={styles.header}>
            {I18n.t("ResetPassword.formHeader")}
          </h1>
          <div className={styles.formSuccessMessageContainer}>
            <div>{I18n.t("ResetPassword.resetEmailSentPt1")}</div>

            <span className={styles.boldedText}>{providedEmail}</span>
            {I18n.t("ResetPassword.resetEmailSentPt2")}
          </div>
          <PrimaryButton
            text={I18n.t("Buttons.backToLogin")}
            event={handleNavigateToLogin}
          />
        </div>
      ) : (
        <>
          <form onSubmit={onSubmit} className={styles.loginForm}>
            <h1 className={styles.header}>
              {I18n.t("ResetPassword.formHeader")}
            </h1>
            <div className={styles.formMessageContainer}>
              {I18n.t("ResetPassword.resetInfo")}
            </div>
            <Controller
              name="email"
              render={({ field }) => (
                <TextField
                  {...register("email")}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  autoComplete="off"
                  variant="outlined"
                  label={I18n.t("FormLabels.email")}
                  type="text"
                  sx={muiStylesAuthentication.inputEmail}
                  inputProps={{
                    style: muiStylesAuthentication.inputEmail
                      .lowercase as React.CSSProperties,
                  }}
                  {...field}
                />
              )}
              control={control}
            />

            <div className={styles.formButtonsContainer}>
              <PrimaryButton
                text={I18n.t("Buttons.sendLink")}
                isSubmit
                disabled={!isValid || loading}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};
