import React, { FC, useEffect, useRef } from "react";
import { I18n } from "react-redux-i18n";
import Seo from "../components/seo";
import { GetResetPasswordForm } from "../components/Authentication/GetResetPassword";
import OnboardingLayout from "../components/Layout/OnboardingLayout";
import { ThemeProvider } from "@mui/material";
import { useCurrentTheme } from "../utils/hooks";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/selectors/i18nSelectors";

const GetResetPasswordEmail: FC = () => {
  const currentTheme = useCurrentTheme();
  const currentLanguage = useSelector(selectLanguage);
  const currLang = useRef(currentLanguage || "pl");

  useEffect(() => {
    if (currLang.current !== currentLanguage) {
      currLang.current = currentLanguage;
    }
  }, [currentLanguage]);

  return (
    <div style={{ height: "100vh" }} className={currentTheme.themeName}>
      <ThemeProvider theme={currentTheme.theme}>
        <Seo title={I18n.t("Common.seo.resetPassword")} />
        <OnboardingLayout>
          <GetResetPasswordForm />
        </OnboardingLayout>
      </ThemeProvider>
    </div>
  );
};

export default GetResetPasswordEmail;
